import './index.scss';
import Logo from '../assets/images/logo.png';

function Installed() {
    return (
        <div className="Installed">
            <img src={Logo} className="InstallLogo" alt="Clearflow" width="150" height="150" />
            <p>Thank you for installing the app.</p>
            <p>You can now close this screen and open the app via your app drawer/home screen.</p>
        </div>
    );
}

export default Installed;
