import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../../../../Components/Busy';
import { ReactComponent as IconArrow } from '../../../../../assets/images/arrow.svg';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../../../../Contexts/Events';

function TestSuiteTestAssetView() {
    const [testSuiteState, setTestSuiteState] = useState({
        busy: true,
        updating: false,
        testSuite: false as any,
        hasError: false
    });

    const loading = useRef(true);
    const { testSuiteId, testSuiteDate, testId, assetId } = useParams();
    const navigate = useNavigate();
    const eventHandler = useContext(EventContext);
    const formRef = React.createRef<HTMLFormElement>();

    // const testSuiteAction = () => {
    //     setTestSuiteState({...testSuiteState, updating: true, buttonActive: false});
    //     let attendance = {...testSuiteState.testSuite.attendance};
    //     if (attendance.signin_at) {
    //         attendance.status = 'signed_out';
    //         attendance.signout_at = moment().format('YYYY-MM-DD HH:mm:ss');
    //         attendance.signout_location = (testSuiteState.location ? testSuiteState.location.coords.latitude + ',' + testSuiteState.location.coords.longitude : attendance.signin_location);
    //     } else {
    //         attendance.status = 'signed_in';
    //         attendance.signin_at = moment().format('YYYY-MM-DD HH:mm:ss');
    //         attendance.signin_location = (testSuiteState.location ? testSuiteState.location.coords.latitude + ',' + testSuiteState.location.coords.longitude : attendance.signin_location);
    //     }
    //     let diff: any = updatedDiff(testSuiteState.testSuite.attendance, attendance);
    //     eventHandler.trigger('log-book:update-local', testSuiteId, Object.keys(diff).reduce((acc: any, key: any) => {
    //         acc['attendance.' + key] = diff[key];
    //         return acc;
    //     }, {}), true);
    // }

    useEffect(() => {
        const testSuiteReceived = (testSuite: any = undefined) => {
            if (!testSuite) {
                navigate('/', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested log book could not be loaded.'
                        }
                    }
                });
                return;
            }
            setTestSuiteState(state => ({...state, updating: false, busy: false, testSuite: testSuite}));
        }

        if (loading.current) {
            eventHandler.trigger('log-book:request', testSuiteId);
            loading.current = false;
        }

        eventHandler.on('log-book:receive:' + testSuiteId, testSuiteReceived);
        return () => {
            eventHandler.off('log-book:receive:' + testSuiteId, testSuiteReceived);
        }
    }, [testSuiteState, testSuiteId, navigate, eventHandler]);

    const toggleConfiguration = (configuration_id: any) => {
        return () => {
            let testIdx = testSuiteState.testSuite.tests.map((v: any) => v.id.toString()).indexOf(testId),
                assetIdx = testSuiteState.testSuite.tests[testIdx].assets.map((v: any) => v.id.toString()).indexOf(assetId);
            
            testSuiteState.testSuite.tests[testIdx].assets[assetIdx].configurations[configuration_id].open = !!!testSuiteState.testSuite.tests[testIdx].assets[assetIdx].configurations[configuration_id].open;
            setTestSuiteState({...testSuiteState, testSuite: testSuiteState.testSuite});
        };
    };

    const setConfigurationStatus = (configuration_id: any, status: any) => {
        return () => {
            let testIdx = testSuiteState.testSuite.tests.map((v: any) => v.id.toString()).indexOf(testId),
                assetIdx = testSuiteState.testSuite.tests[testIdx].assets.map((v: any) => v.id.toString()).indexOf(assetId);

            testSuiteState.testSuite.tests[testIdx].assets[assetIdx].configurations[configuration_id].status = status;
            setTestSuiteState({...testSuiteState, testSuite: testSuiteState.testSuite});
        };
    };

    const saveAsset = () => {
        return () => {
            let fd = new FormData(formRef.current as any);
            console.log(Object.fromEntries(fd));
            window.alert('Add changes to sync queue...');
        };
    };

    const skipAsset = (status: any = 'skip') => {
        return () => {
            let testIdx = testSuiteState.testSuite.tests.map((v: any) => v.id.toString()).indexOf(testId),
                assetIdx = testSuiteState.testSuite.tests[testIdx].assets.map((v: any) => v.id.toString()).indexOf(assetId);

            testSuiteState.testSuite.tests[testIdx].assets[assetIdx].status = status;
            setTestSuiteState({...testSuiteState, testSuite: testSuiteState.testSuite});
        };
    };

    const test = (testSuiteState.busy ? false : testSuiteState.testSuite.tests.reduce((acc: any, item: any) => {
        if (item.id.toString() === testId) {
            acc = item;
        }
        return acc;
    }));

    const asset = (test ? test.assets.reduce((acc: any, item: any) => {
        if (item.id.toString() === assetId) {
            acc = item;
        }
        return acc;
    }) : false);

    return (
        <form ref={formRef} method="post" className={`TestSuiteTestAssetView ${testSuiteState.busy ? 'is--busy' : ''}`}>
            {testSuiteState.busy && (
                <Busy />
            )}
            {!testSuiteState.busy && (
                <>
                    <Link className="Back" to={'/log-books/view/' + testSuiteId + '/' + testSuiteDate + '/' + testId}>
                        <span>
                            <IconArrow width={15.91} height={24.6} />
                        </span>
                        Back to log book
                    </Link>
                    {testSuiteState.testSuite.status === 'complete' && (
                        <p className="TestSuiteTestAssetCompleted">This log book has been completed.</p>
                    )}
                    <div className="TestSuiteTestAssetViewDetail">
                        <h2>{moment(testSuiteDate, 'YYYY-MM-DD').format('dddd Do MMMM')}</h2>
                        <h1>{testSuiteState.testSuite.client.name}</h1>
                        <h3>{asset.name}</h3>
                    </div>
                    {testSuiteState.hasError && (
                        <p className="Error">Please check all configurations before attempting to save.</p>
                    )}
                    <p><strong>Configurations</strong></p>
                    {asset.configurations.map((configuration: any, idx: any) => {
                        let status: any = 'pending';
                        return (
                            <div className={'TestSuiteTestAssetConfiguration ' + status + ' ' + (!!configuration.open ? 'open' : '')} key={idx}>
                                <div onClick={toggleConfiguration(idx)}>
                                    <strong>{configuration.name}</strong>
                                </div>
                                <span onClick={toggleConfiguration(idx)}><IconArrow width={15.91} height={24.6} /></span>
                                <div className="content">
                                    <div className="content-inner">
                                        {['gt','gte','lt','lte'].indexOf(configuration.type) > -1 && (
                                            <div className="input-unit">
                                                <input type="number" name={'configuration[' + configuration.id + '][value]'} />
                                                <span>{configuration.configuration.unit}</span>
                                            </div>
                                        )}
                                        {['date'].indexOf(configuration.type) > -1 && (
                                            <div className="input-date">
                                                <input type="date" name={'configuration[' + configuration.id + '][value]'} />
                                            </div>
                                        )}
                                        {['file','image'].indexOf(configuration.type) > -1 && (
                                            <div className="input-file">
                                                <input type="file" accept={(configuration.type === 'image' ? 'image/*' : configuration.configuration.allowed_extension.replace(/\*/gi, ''))} name={'configuration[' + configuration.id + '][value]'} />
                                            </div>
                                        )}
                                        {['text'].indexOf(configuration.type) > -1 && (
                                            <div className="input-text">
                                                <input type="text" name={'configuration[' + configuration.id + '][value]'} />
                                            </div>
                                        )}
                                        {['textarea'].indexOf(configuration.type) > -1 && (
                                            <div className="input-textarea">
                                                <textarea name={'configuration[' + configuration.id + '][value]'}></textarea>
                                            </div>
                                        )}
                                        {['checkbox'].indexOf(configuration.type) > -1 && (
                                            <div className="input-checkbox">
                                                <input type="radio" name={'configuration[' + configuration.id + '][value]'} id={`configuration-${configuration.id}.1`} value="1" />
                                                <label htmlFor={`configuration-${configuration.id}.1`}>{configuration.configuration.labels[1]}</label>
                                                <input type="radio" name={'configuration[' + configuration.id + '][value]'} id={`configuration-${configuration.id}.0`} value="0" />
                                                <label htmlFor={`configuration-${configuration.id}.0`}>{configuration.configuration.labels[0]}</label>
                                            </div>
                                        )}
                                        <div className="ConfigurationActions">
                                            <div className="ButtonGroup">
                                                <button type="button" className={'pass' + (configuration.status === 'pass' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'pass')}>Pass</button>
                                                <button type="button" className={'fail' + (configuration.status === 'fail' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'fail')}>Fail</button>
                                                <button type="button" className={'skip' + (configuration.status === 'skip' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'skip')}>Skip</button>
                                            </div>
                                            <div className="ConfigurationReason">
                                                <p>Comments/Reason</p>
                                                <textarea name={`configuration[${configuration.id}][comments]`}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="FormActions">
                        <div className="ButtonGroup">
                            <button type="button" onClick={skipAsset()}>Skip All</button>
                            {asset.status === 'skip' && (
                                <button type="button" onClick={skipAsset('pending')}>Cancel Skip</button>
                            )}
                        </div>
                        {asset.status === 'skip' && (
                            <div className="ConfigurationReason">
                                <p>Comments/Reason</p>
                                <textarea></textarea>
                            </div>
                        )}
                        <div className="ButtonGroup">
                            <button type="button" className="pass" onClick={saveAsset()}>Save</button>
                        </div>
                    </div>
                </>
            )}

        </form>
    );
}

export default TestSuiteTestAssetView;