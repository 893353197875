import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../Contexts/Events';
import Modal from '../../Components/Modal';

function TestSuiteView() {

    const location = useLocation();

    const [testSuiteState, setTestSuiteState] = useState({
        busy: true,
        updating: false,
        notice: false as any,
        error: false as any,
        values: [] as any,
        actions: [] as any,
        testSuites: [],
        signOffValid: false,
        signOffSelection: '',
        signOffComment: '',
        ...location.state
    });

    const loading = useRef(true);
    const { clientId, testSuiteDate, siteId, buildingId } = useParams();
    const navigate = useNavigate();
    const eventHandler = useContext(EventContext);

    useEffect(() => {
        const testSuitesReceived = (testSuites: any = undefined) => {
            if (testSuites.length === 0) {
                navigate('/', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested test suite could not be loaded.'
                        }
                    }
                });
                return;
            }

            eventHandler.trigger('reading-values:request', {
                client_id: Number.parseInt(clientId ?? ''),
                date: testSuiteDate
            }, 'local', 'current');

            setTestSuiteState((state: any) => ({...state, updating: false, busy: false, testSuites}));
        }

        const readingValuesReceived = (values: any, from: any, source: any) => {
            if (source === 'current') {
                values = Object.values(values.reduce((acc: any, value: any) => {
                    let key = [value.test_suite_test_id, value.test_configuration_id, value.test_suite_test_asset_id].join('-');
                    acc[key] = value;
                    return acc;
                }, {}));
                setTestSuiteState((state: any) => ({...state, values}));

                eventHandler.trigger('actions:request', {
                    type: 'readingValue'
                });
            }
        }

        const actionsReceived = (actions: any) => {
            actions = Object.values(actions.reduce((acc: any, action: any) => {
                let key = [action.test_suite_id, action.date].join('-');
                acc[key] = action;
                return acc;
            }, {}));
            setTestSuiteState({...testSuiteState, actions});
        }

        if (loading.current) {
            eventHandler.trigger('test-suites-by-client:request', clientId);
            loading.current = false;
        }

        eventHandler.on('test-suites-by-client:receive:' + clientId, testSuitesReceived);
        eventHandler.on('reading-values:receive', readingValuesReceived);
        eventHandler.on('actions:receive', actionsReceived);
        return () => {
            eventHandler.off('test-suites-by-client:receive:' + clientId, testSuitesReceived);
            eventHandler.off('reading-values:receive', readingValuesReceived);
            eventHandler.off('actions:receive', actionsReceived);
        }
    }, [testSuiteState, testSuiteDate, clientId, navigate, eventHandler]);

    const showAsset = (asset_id: any) => {
        return () => {
            navigate('/test-suites/view/' + clientId + '/' + testSuiteDate + '/site/' + siteId + '/building/' + buildingId + '/' + asset_id);
        };
    };

    const showSite = (site_id: any) => {
        return () => {
            navigate('/test-suites/view/' + clientId + '/' + testSuiteDate + '/site/' + site_id);
        };
    };

    const showBuilding = (building_id: any) => {
        return () => {
            navigate('/test-suites/view/' + clientId + '/' + testSuiteDate + '/site/' + siteId + '/building/' + building_id);
        };
    };

    const complete = testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        if (testSuiteDate && item.scheduled_dates[testSuiteDate] !== 'complete') {
            acc = false;
        }
        return acc;
    }, true);

    const client = testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        if (item.client) {
            acc = item.client;
        }
        return acc;
    }, false);

    const site = (siteId !== undefined ? testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        item.tests.forEach((test: any) => {
            test.assets.forEach((asset: any) => {
                if (asset.site && Number.parseInt(asset.site.id) === Number.parseInt(siteId)) {
                    acc = asset.site;
                    return true;
                }
            });
            if (acc) {
                return true;
            }
        });
        return acc;
    }, false) : undefined);

    const building = (buildingId !== undefined ? testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        item.tests.forEach((test: any) => {
            test.assets.forEach((asset: any) => {
                if (asset.building && Number.parseInt(asset.building.id) === Number.parseInt(buildingId)) {
                    acc = asset.building;
                    return true;
                }
            });
            if (acc) {
                return true;
            }
        });
        return acc;
    }, false) : undefined);

    const sites = (siteId === undefined ? testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        acc = item.tests.reduce((acc: any, test: any) => {
            test.assets.forEach((asset: any) => {
                if (!asset.site) {
                    return acc;
                }
                if (!(asset.site.id in acc)) {
                    acc[asset.site.id] = {
                        name: asset.site.name,
                        tests: [],
                        completed: {}
                    };
                }
                testSuiteState.values.forEach((value: any) => {
                    if (value.asset_id === asset.asset_id) {
                        asset.configurations.forEach((configuration: any) => {
                            if (value.test_configuration_id === configuration.test_configuration_id) {
                                if (value.status !== 'pending' && configuration.name !== 'Sample Temperature') {
                                    acc[asset.site.id].completed[value.asset_id + '-' + value.test_configuration_id] = true;
                                }
                            }
                        });
                    }
                });
                testSuiteState.actions.forEach((value: any) => {
                    if (value.changes.asset_id === asset.asset_id) {
                        asset.configurations.forEach((configuration: any) => {
                            if (value.changes.test_configuration_id === configuration.test_configuration_id) {
                                if (value.changes.status !== 'pending' && configuration.name !== 'Sample Temperature') {
                                    acc[asset.site.id].completed[value.changes.asset_id + '-' + value.changes.test_configuration_id] = true;
                                }
                            }
                        });
                    }
                })
                acc[asset.site.id].tests.push(test);
            });
            return acc;
        }, acc)
        return acc;
    }, {}) : {});

    const buildings = (siteId !== undefined && buildingId === undefined ? testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        acc = item.tests.reduce((acc: any, test: any) => {
            test.assets.forEach((asset: any) => {
                if (!asset.building || Number.parseInt(asset.site.id) !== Number.parseInt(siteId)) {
                    return acc;
                }
                if (!(asset.building.id in acc)) {
                    acc[asset.building.id] = {
                        name: asset.building.name,
                        tests: [],
                        completed: {}
                    };
                }
                testSuiteState.values.forEach((value: any) => {
                    if (value.asset_id === asset.asset_id) {
                        asset.configurations.forEach((configuration: any) => {
                            if (value.test_configuration_id === configuration.test_configuration_id) {
                                if (value.status !== 'pending' && configuration.name !== 'Sample Temperature') {
                                    acc[asset.building.id].completed[value.asset_id + '-' + value.test_configuration_id] = true;
                                }
                            }
                        });
                    }
                });
                testSuiteState.actions.forEach((value: any) => {
                    if (value.changes.asset_id === asset.asset_id) {
                        asset.configurations.forEach((configuration: any) => {
                            if (value.changes.test_configuration_id === configuration.test_configuration_id) {
                                if (value.changes.status !== 'pending' && configuration.name !== 'Sample Temperature') {
                                    acc[asset.building.id].completed[value.changes.asset_id + '-' + value.changes.test_configuration_id] = true;
                                }
                            }
                        });
                    }
                })
                acc[asset.building.id].tests.push(test);
            });
            return acc;
        }, acc)
        return acc;
    }, {}) : {});

    const assets = ((Object.keys(sites).length === 0 || siteId !== undefined) && (Object.keys(buildings).length === 0 || buildingId !== undefined) ? testSuiteState.testSuites.reduce(function(acc: any, item: any) {
        acc = item.tests.reduce((acc: any, test: any) => {
            test.assets.forEach((asset: any) => {
                if (asset.site && Number.parseInt(siteId as any) !== Number.parseInt(asset.site.id)) {
                    return true;
                }
                if (asset.building && Number.parseInt(buildingId as any) !== Number.parseInt(asset.building.id)) {
                    return true;
                }
                if (!(asset.asset_id in acc)) {
                    acc[asset.asset_id] = {
                        name: asset.name,
                        tests: [],
                        completed: {}
                    };
                }
                testSuiteState.values.forEach((value: any) => {
                    if (value.asset_id === asset.asset_id) {
                        asset.configurations.forEach((configuration: any) => {
                            if (value.test_configuration_id === configuration.test_configuration_id) {
                                if (value.status !== 'pending' && configuration.name !== 'Sample Temperature') {
                                    acc[asset.asset_id].completed[value.asset_id + '-' + value.test_configuration_id] = true;
                                }
                            }
                        });
                    }
                })
                testSuiteState.actions.forEach((value: any) => {
                    if (value.changes.asset_id === asset.asset_id) {
                        asset.configurations.forEach((configuration: any) => {
                            if (value.changes.test_configuration_id === configuration.test_configuration_id) {
                                if (value.changes.status !== 'pending' && configuration.name !== 'Sample Temperature') {
                                    acc[asset.asset_id].completed[value.changes.asset_id + '-' + value.changes.test_configuration_id] = true;
                                }
                            }
                        });
                    }
                })
                acc[asset.asset_id].tests.push(test);
            });
            return acc;
        }, acc)
        return acc;
    }, {}) : {});

    const signOff = () => {
        testSuiteState.actions.forEach((action: any) => {
            action.completed_at = moment().format('YYYY-MM-DD HH:mm:ss');
            action.completed = true;
            action.completion_comment = (testSuiteState.signOffSelection !== 'other' ? testSuiteState.signOffSelection : testSuiteState.signOffComment);
        });
        eventHandler.trigger('actions:update', testSuiteState.actions);
        navigate('/');
    };

    const validateSignOff = () => {
        setTestSuiteState((state: any) => ({...state, signOffValid: (state.signOffSelection !== '' && (state.signOffSelection !== 'other' || (state.signOffSelection === 'other' && state.signOffComment.length > 0)))}));
    }

    const updateSignOffOption = (e: any) => {
        setTestSuiteState((state: any) => ({...state, signOffSelection: e.target.options[e.target.selectedIndex].value}));
        validateSignOff();
    }

    const updateSignOffComment = (e: any) => {
        setTestSuiteState((state: any) => ({...state, signOffComment: e.target.value}));
        validateSignOff();
    }

    let can_complete: any = true;

    return (
        <div className={`TestSuiteView ${testSuiteState.busy ? 'is--busy' : ''}`}>
            {testSuiteState.notice !== false && (
                <Modal onDismiss={() => setTestSuiteState({ ...testSuiteState, notice: false })} title={testSuiteState.notice.title}>{testSuiteState.notice.message}</Modal>
            )}
            {testSuiteState.error !== false && (
                <Modal onDismiss={() => setTestSuiteState({ ...testSuiteState, error: false })} title={testSuiteState.error.title}>{testSuiteState.error.message}</Modal>
            )}
            {testSuiteState.busy && (
                <Busy />
            )}
            {!testSuiteState.busy && (
                <>
                    {buildingId !== undefined && (
                        <Link className="Back" to={`/test-suites/view/${clientId}/${testSuiteDate}/site/${siteId}`}>
                            <span>
                                <IconArrow width={15.91} height={24.6} />
                            </span>
                            Back to {site.name}
                        </Link>
                    )}
                    {siteId !== undefined && buildingId === undefined && (
                        <Link className="Back" to={`/test-suites/view/${clientId}/${testSuiteDate}`}>
                            <span>
                                <IconArrow width={15.91} height={24.6} />
                            </span>
                            Back to test suite
                        </Link>
                    )}
                    {siteId === undefined && (
                        <Link className="Back" to="/">
                            <span>
                                <IconArrow width={15.91} height={24.6} />
                            </span>
                            Back to test suites
                        </Link>
                    )}
                    {complete && (
                        <p className="TestSuiteCompleted">This test suite has been completed.</p>
                    )}
                    <div className="TestSuiteViewDetail">
                        <h2>{moment(testSuiteDate, 'YYYY-MM-DD').format('MMMM YYYY')}</h2>
                        <h1>{client.name}</h1>
                        {siteId === undefined && (
                            <p>{client.address_1}{client.address_2 !== null && client.address_2.length ? ', ' + client.address_2 : ''}, {client.county}<br />{client.postcode}</p>
                        )}
                        {building && (
                            <p><strong>{building.name}</strong></p>
                        )}
                        {site && (
                            <>
                                <p className="TestSuiteLocation"><span>{site.name}</span></p>
                                <p>{site.address_1}{site.address_2 !== null && site.address_2.length ? ', ' + site.address_2 : ''}, {site.county}<br />{site.postcode}</p>
                            </>
                        )}
                    </div>
                    {Object.keys(assets).length > 0 && (
                        <>
                            <p><strong>Assets</strong></p>
                            {Object.keys(assets).map((asset_id: any, idx: any) => {
                                let status: any = 'pending',
                                    asset: any = assets[asset_id],
                                    completed: any = Object.keys(asset.completed).length,
                                    tests: any = asset.tests.reduce((acc: any, test: any) => {
                                        test.assets.forEach((_asset: any) => {
                                            if (_asset.asset_id === Number.parseInt(asset_id)) {
                                                let offset = 0;
                                                if (_asset.configurations.filter((c: any) => c.name === 'Sample Taken').length) {
                                                    offset = 1;
                                                }
                                                acc += (_asset.configurations.length - offset);
                                            }
                                        });
                                        return acc;
                                    }, 0);

                                if (completed > 0) {
                                    status = 'started';
                                }
                                if (completed === tests) {
                                    status = 'complete';
                                } else {
                                    can_complete = false;
                                }
                                return (
                                    <div className={'TestSuiteTest ' + status} key={idx} onClick={showAsset(asset_id)}>
                                        <div>
                                            <strong>{asset.name}</strong>
                                            <p>{tests} task{tests === 1 ? '' : 's'} {completed > 0 && (<span> - {(tests - completed) + ' task' + (completed === 1 ? '' : 's') + ' remaining'}</span>)}</p>
                                        </div>
                                        <span><IconArrow width={15.91} height={24.6} /></span>
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {Object.keys(sites).length > 0 && (
                        <>
                            <p><strong>Locations</strong></p>
                            {Object.keys(sites).sort((a: any, b: any) => {
                                return sites[a].name.localeCompare(sites[b].name);
                            }).map((site_id: any, idx: any) => {
                                let status: any = 'pending',
                                    site: any = sites[site_id],
                                    completed: any = Object.keys(site.completed).length,
                                    tests: any = site.tests.reduce((acc: any, test: any) => {
                                        test.assets.forEach((_asset: any) => {
                                            if (_asset.site.id === Number.parseInt(site_id)) {
                                                let offset = 0;
                                                if (_asset.configurations.filter((c: any) => c.name === 'Sample Taken').length) {
                                                    offset = 1;
                                                }
                                                acc += (_asset.configurations.length - offset);
                                            }
                                        });
                                        return acc;
                                    }, 0);

                                if (completed > 0) {
                                    status = 'started';
                                }
                                if (completed === tests) {
                                    status = 'complete';
                                } else {
                                    can_complete = false;
                                }
                                return (
                                    <div className={'TestSuiteTest ' + status} key={idx} onClick={showSite(site_id)}>
                                        <div>
                                            <strong>{site.name}</strong>
                                            <p>{tests} task{tests === 1 ? '' : 's'} {completed > 0 && (<span> - {(tests - completed) + ' task' + (completed === 1 ? '' : 's') + ' remaining'}</span>)}</p>
                                        </div>
                                        <span><IconArrow width={15.91} height={24.6} /></span>
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {Object.keys(buildings).length > 0 && (
                        <>
                            <p><strong>Buildings</strong></p>
                            {Object.keys(buildings).sort((a: any, b: any) => {
                                return buildings[a].name.localeCompare(buildings[b].name);
                            }).map((building_id: any, idx: any) => {
                                let status: any = 'pending',
                                    building: any = buildings[building_id],
                                    completed: any = Object.keys(building.completed).length,
                                    tests: any = building.tests.reduce((acc: any, test: any) => {
                                        test.assets.forEach((_asset: any) => {
                                            if (_asset.building.id === Number.parseInt(building_id)) {
                                                let offset = 0;
                                                if (_asset.configurations.filter((c: any) => c.name === 'Sample Taken').length) {
                                                    offset = 1;
                                                }
                                                acc += (_asset.configurations.length - offset);
                                            }
                                        });
                                        return acc;
                                    }, 0);

                                if (completed > 0) {
                                    status = 'started';
                                }
                                if (completed === tests) {
                                    status = 'complete';
                                } else {
                                    can_complete = false;
                                }
                                return (
                                    <div className={'TestSuiteTest ' + status} key={idx} onClick={showBuilding(building_id)}>
                                        <div>
                                            <strong>{building.name}</strong>
                                            <p>{tests} task{tests === 1 ? '' : 's'} {completed > 0 && (<span> - {(tests - completed) + ' task' + (completed === 1 ? '' : 's') + ' remaining'}</span>)}</p>
                                        </div>
                                        <span><IconArrow width={15.91} height={24.6} /></span>
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {can_complete !== false && (
                        <>
                            <p><strong>Sign Off</strong></p>
                            <p>To mark this as complete, please select the appropriate result or enter a comment.</p>
                            <select onChange={updateSignOffOption} defaultValue={testSuiteState.signOffSelection}>
                                <option value="">-- Select --</option>
                                <option value="Visit complete all monitoring satisfactory">Visit complete all monitoring satisfactory</option>
                                <option value="Visit complete see actions and recommendation">Visit complete see actions and recommendation</option>
                                <option value="other">Other</option>
                            </select>
                            {testSuiteState.signOffSelection === 'other' && (
                                <>
                                    <textarea onChange={updateSignOffComment} defaultValue={testSuiteState.signOffComment} placeholder="Please enter a comment to provide to the customer." rows={8}></textarea>
                                </>
                            )}
                            <button onClick={(e) => signOff()} type="button" disabled={!testSuiteState.signOffValid}>Complete</button>
                        </>
                    )}
                </>
            )}

        </div>
    );
}

export default TestSuiteView;