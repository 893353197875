import { createContext } from "react";

export default class Authentication {
    token_key: string = 'cf-token';
    expiration_key: string = 'cf-token-expiration';
    onboard_key: string = 'cf-onboard';
    user_id_key: string = 'cf-user_id';
    permissions_key: string = 'cf-permissions';
    online_key: string = 'cf-online';

    token() {
        return localStorage.getItem(this.token_key);
    }

    expires() {
        const expires: any = localStorage.getItem(this.expiration_key);
        if (expires) {
            return expires * 1000;
        }
        return Date.now();
    }

    check() {
        return this.token() !== null && this.expires() > Date.now();
    }

    logout() {
        localStorage.removeItem(this.token_key);
        localStorage.removeItem(this.expiration_key);
        localStorage.removeItem(this.onboard_key);
        localStorage.removeItem(this.user_id_key);
        localStorage.removeItem(this.permissions_key);
    }

    login(token: string, expires: number) {
        localStorage.setItem(this.token_key, token);
        localStorage.setItem(this.expiration_key, expires.toString());
    }

    userId() {
        return Number.parseInt(localStorage.getItem(this.user_id_key) as string);
    }

    setUserId(user_id: any) {
        localStorage.setItem(this.user_id_key, user_id);
    }

    hasPermissions() {
        let permissions: any = localStorage.getItem(this.permissions_key);
        if (permissions) {
            permissions = JSON.parse(permissions);
        }
        permissions = {            
            location: undefined,
            ...permissions, 
        };
        return permissions.location !== undefined;
    }

    setPermissions(permissions: any) {
        localStorage.setItem(this.permissions_key, JSON.stringify(permissions));
    }

    getOnline() {
        const result = parseInt((localStorage.getItem(this.online_key) ?? '1'), 10);
        if (result) {
            return navigator.onLine;
        }
        return false;
    }

    setOnline(online: boolean) {
        localStorage.setItem(this.online_key, online ? '1' : '0');
    }
}


export const AuthenticationContext = createContext(null as any);