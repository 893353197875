import './index.scss';

function Debug() {
    const build_version = process.env.REACT_APP_VERSION || '0.0.0';
    const build_date = process.env.REACT_APP_BUILD_DATE || 'N/A';

    const updateApp = async () => {
        const registration = await navigator.serviceWorker.getRegistration();
        registration?.update();
    };

    const restartApp = async () => {
        (window.location as any).reload(true);
    };

    return (
        <div className="Debug">
            <p><strong>Build Version</strong><br />{build_version}</p>
            <p><strong>Build Date</strong><br />{build_date}</p>
            <button type="button" onClick={(e) => updateApp()}>Check for Updates</button>
            <button type="button" onClick={(e) => restartApp()}>Restart App</button>
        </div>
    );
}

export default Debug;
