import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../Contexts/Authentication';
import Logo from '../../assets/images/logo.png';
import { ReactComponent as IconShifts } from '../../assets/images/shifts.svg';
import { ReactComponent as IconProfile } from '../../assets/images/proflie.svg';
import { EventContext } from '../../Contexts/Events';
import { DataContext } from '../../Contexts/Data';


function Private() {
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useRef(true);
  const eventHandler = useContext(EventContext);

  const dataLayer = useContext(DataContext);
  dataLayer.getApi().registerNavigate(navigate);

  const [online, setOnline] = useState(authentication.getOnline());

  useEffect(() => {
    if (!authentication.check()) {
      navigate('/login');
      return;
    }
    if (!authentication.hasPermissions()) {
      navigate('/setup');
      return;
    }
    if (loading.current) {
      loading.current = false;
    }
  }, [authentication, navigate, eventHandler, dataLayer]);

  useEffect(() => {
    const networkChange = () => {
      setOnline(authentication.getOnline());
    }
    eventHandler.on('network:change', networkChange);
    if (authentication.check()) {
      eventHandler.trigger('profile:request');
    }
    return () => {
      eventHandler.off('network:change', networkChange);
    }
  }, [eventHandler, authentication]);

  const [debugState, setDebugState] = useState(0);

  const detectDebug = (e: any) => {
    if (debugState === 0) {
      setTimeout(() => {
        setDebugState(0);
      }, 3000);
    }
    setDebugState((value: any) => value + 1);
    if (debugState >= 7) {
      e.preventDefault();
      setDebugState(0);
      if (window.confirm('Show debugging information?')) {
        navigate('/debug');
      }
    }
  }

  const toggleOnline = (e: any) => {
    authentication.setOnline(!e.target.checked);
    setOnline(!e.target.checked);
    eventHandler.trigger('network:change', !e.target.checked);
  }

  return (
    <div className="PrivateLayout" onClick={detectDebug}>
      <header>
        <Link to="/">
          <img src={Logo} className="PrivateLogo" alt="Clearflow" width="150" height="150" />
        </Link>
        {authentication.hasPermissions() && (
          <div className="Actions">
            <label className="Toggle">
              <input type="checkbox" id="toggle-network" checked={!online} onChange={toggleOnline} />
              <span></span>
            </label>
            <div className="ToggleLabel">Online / Offline</div>
            {online && (
              <button type="button" onClick={() => eventHandler.trigger('actions:sync')} className="Sync">Sync</button>
            )}
          </div>
        )}
      </header>
      <main>
        {!online && (
          <div className="Offline">You are currently offline.<br />Data will be synchronised once reconnected.</div>
        )}
        <Outlet />
      </main>
      {authentication.hasPermissions() && (
        <footer>
          <button onClick={() => navigate('/')} className={location.pathname === '/' || location.pathname.indexOf('/shifts') > -1 ? 'active' : ''}>
            <IconShifts width={32} height={32} />
          </button>
          <button onClick={() => navigate('/profile')} className={location.pathname.indexOf('/profile') > -1 ? 'active' : ''}>
            <IconProfile width={32} height={32} />
          </button>
        </footer>
      )}
    </div>
  );
}

export default Private;
