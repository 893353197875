import { createContext } from "react";
import Dexie from 'dexie';

export default class DB {

    inst: any;

    constructor() {
        this.init();
    }

    init() {
        this.inst = new Dexie('Clearflow');
        this.inst.version(1).stores({
            testSuites: 'id,client.id,starts_at',
            readingValues: 'id++,asset_id,date,test_configuration_id,test_suite_id,test_suite_test_asset_id',
            recommendations: 'id',
            actions: 'id++,type,changes',
            profile: 'id'
        });
    }

    getOrSet(obj: object, path: string = '', newValue: any = undefined) {
        const l = typeof path === 'string' ? path.split('.') : path;
        return l.reduce((carry: any, item: any, idx) => {
            const leaf = carry[item];
            if (l.length - idx === 1) {
                carry[item] = newValue === undefined ? leaf : newValue;
                return newValue === undefined ? leaf : obj;
            }
            carry[item] = leaf || {};
            return carry[item];
        }, obj)
    }

    applyChanges(row: any, changes: any) {
        Object.keys(changes).forEach((key: any) => {
            this.getOrSet(row, key, changes[key]);
        });
        return row;
    }

    async add(table: string, row: any) {
        return await this.inst[table].add(row);
    }

    async bulkAdd(table: string, rows: any) {
        return await this.inst[table].bulkAdd(rows);
    }

    async bulkPut(table: string, rows: any) {
        return await this.inst[table].bulkPut(rows);
    }

    async delete(table: string, id: any) {
        await this.inst[table].delete(id);
    }

    async bulkDelete(table: string, keys: any) {
        await this.inst[table].bulkDelete(table, keys);
    }

    async update(table: string, id: any, row: any) {
        await this.inst[table].update(id, row);
    }

    async put(table: string, row: any) {
        await this.inst[table].put(row);
    }

    async get(table: string, query: any) {
        return await this.inst[table].get(query);
    }

    async getAll(table: string, query: any = {}, asCollection: boolean = false) {
        let q = this.inst[table];
        if (Object.keys(query).length) {
            q = q.where(query);
        }
        if (asCollection) {
            return await q.toCollection(); 
        }
        return await q.toArray();
    }

    async clear(table: string) {
        return await this.inst[table].clear();
    }

    async flush() {
        await this.inst.delete();
    }

    getDB() {
        return this.inst;
    }
}

export const DBContext = createContext(null as any);