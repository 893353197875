import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { EventContext } from '../../Contexts/Events';

function TestSuiteList() {

    const navigate = useNavigate();
    const loading = useRef(true);
    const location = useLocation();
    const eventHandler = useContext(EventContext);

    const [testSuiteState, setTestSuiteState] = useState({
        busy: true,
        error: false as any,
        testSuites: [] as any,
        page: 0,
        pageKey: '',
        totalPages: 0,
        ...location.state
    });

    const formatTestSuites = (testSuites: any) => {
        let output: any = {}, keys: any = [];
        testSuites.forEach((testSuite: any) => {
            if (Object.keys(testSuite.scheduled_dates).length) {
                Object.keys(testSuite.scheduled_dates).forEach((scheduled_date: any) => {
                    if (['pending', 'started'].indexOf(testSuite.scheduled_dates[scheduled_date]) !== -1) {
                        const date = moment(scheduled_date, 'YYYY-MM-DD'),
                            month = date.format('MMMM YYYY');

                        if (!(month in output)) {
                            output[month] = {};
                            keys.push(month);
                        }
                        if (!(testSuite.client.id in output[month])) {
                            output[month][testSuite.client.id] = {
                                id: testSuite.client.id,
                                name: testSuite.client.name,
                                scheduled_date,
                                assets: [],
                                tests: 0,
                                status: 'pending'
                            };
                        }
                        if (['started'].indexOf(testSuite.scheduled_dates[output[month]]) !== -1) {
                            output[month][testSuite.client.id].status = 'started';
                        }
                        output[month][testSuite.client.id].assets = testSuite.tests.reduce((acc: any, item: any) => {
                            item.assets.forEach((asset: any) => {
                                if (acc.indexOf(asset.asset_id) === -1) {
                                    acc.push(asset.asset_id);
                                }
                            });
                            return acc;
                        }, output[month][testSuite.client.id].assets);
                        output[month][testSuite.client.id].tests += testSuite.tests.length;
                    }
                });
            }
        });

        keys = keys.sort(function (a: any, b: any) {
            a = moment(a, 'MMMM YYYY');
            b = moment(b, 'MMMM YYYY');
            return a.diff(b);
        });

        keys = keys.slice(0, 12);

        output = keys.reduce((acc: any, key: any, idx: any) => {
            acc[key] = output[key];
            return acc;
        }, {});

        /**
         * GR 09/04/24 - Eden requested that only the current month
         * shows and otherwise it should show nothing.
         */
        const now = moment().format('MMMM YYYY');
        if (now in output) {
            output = {[now]: output[now]};
        } else {
            output = {};
        }

        return output;
    }

    const showTestSuite = (testSuite: any) => {
        return () => {
            navigate('/test-suites/view/' + testSuite.id + '/' + testSuite.scheduled_date);
        };
    }

    const requestTestSuites = (): Promise<void> => {
        setTestSuiteState({ ...testSuiteState, busy: true });
        eventHandler.trigger('test-suites:request');
        return Promise.resolve();
    };

    useEffect(() => {
        const receiveTestSuites = (testSuites: any) => {
            testSuites = formatTestSuites(testSuites);
            setTestSuiteState({ ...testSuiteState, busy: false, testSuites, page: 0, totalPages: Object.keys(testSuites).length, pageKey: (Object.keys(testSuites).length ? Object.keys(testSuites)[0] : false) });

            eventHandler.trigger('actions:request', {
                type: 'testSuite',
            });
        }
        const receivedActions = (actions: any) => {
            actions = Object.values(actions.reduce((acc: any, action: any) => {
                let key = [action.test_suite_id, action.date].join('-');
                acc[key] = action;
                return acc;
            }, {}));
            actions.forEach((action: any) => {
                let d: any = moment(action.date, 'YYYY-MM-DD').format('MMMM YYYY');
                if (d in testSuiteState.testSuites) {
                    testSuiteState.testSuites[d].forEach((testSuite: any) => {
                        if (testSuite.id === action.test_suite_id) {
                            testSuite.status = action.status;
                        }
                    });
                }
            });

            setTestSuiteState({ ...testSuiteState });
        }
        const actionsSync = () => {
            setTestSuiteState({ ...testSuiteState, busy: true });
        }
        if (loading.current) {
            eventHandler.trigger('actions:sync');
            loading.current = false;
        }
        eventHandler.on('test-suites:receive', receiveTestSuites);
        eventHandler.on('actions:sync', actionsSync);
        eventHandler.on('actions:receive', receivedActions);
        return () => {
            eventHandler.off('test-suites:receive', receiveTestSuites);
            eventHandler.off('actions:sync', actionsSync);
            eventHandler.off('actions:receive', receivedActions);
        };
    }, [eventHandler, testSuiteState]);

    const moveNext = () => {
        setTestSuiteState((state: any) => ({ ...state, page: state.page + 1, pageKey: Object.keys(state.testSuites)[state.page + 1] }));
    }

    const movePrevious = () => {
        setTestSuiteState((state: any) => ({ ...state, page: state.page - 1, pageKey: Object.keys(state.testSuites)[state.page - 1] }));
    }

    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState(0);

    const pullStart = (e: any) => {
        const { screenY } = e.targetTouches[0];
        setStartPoint(screenY);
    }

    const pull = (e: any) => {
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        setPullChange(startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    }

    const endPull = (e: any) => {
        setStartPoint(0);
        setPullChange(0);
        if (pullChange > 220) requestTestSuites();
    }

    useEffect(() => {
        window.addEventListener('touchstart', pullStart);
        window.addEventListener('touchmove', pull);
        window.addEventListener('touchend', endPull);
        return () => {
            window.removeEventListener('touchstart', pullStart);
            window.removeEventListener('touchmove', pull);
            window.removeEventListener('touchend', endPull);
        };
    });

    return (
        <div className={`TestSuites ${(testSuiteState.busy ? 'is--busy' : '')}`}>
            {testSuiteState.error !== false && (
                <Modal onDismiss={() => setTestSuiteState({ ...testSuiteState, error: false })} title={testSuiteState.error.title}>{testSuiteState.error.message}</Modal>
            )}
            <h1>Upcoming Test suites</h1>
            <div className="TestSuitesList" style={{ marginTop: pullChange / 3.118 || "" }}>
                {testSuiteState.busy && (
                    <div className="TestSuitesListBusy">
                        <Busy />
                    </div>
                )}
                {Object.keys(testSuiteState.testSuites).length === 0 && (
                    <div className="TestSuitesList--Empty">You have not been assigned any test suites.</div>
                )}
                {Object.keys(testSuiteState.testSuites).length > 0 && Object.values(testSuiteState.testSuites[testSuiteState.pageKey]).sort((a: any, b: any) => {
                    a = moment(a.scheduled_date, 'YYYY-MM-DD');
                    b = moment(b.scheduled_date, 'YYYY-MM-DD');
                    return a.diff(b);
                }).map((testSuite: any, idx: any) => {
                    return (
                        <React.Fragment key={idx}>
                            {idx === 0 && (<h2>{testSuiteState.pageKey}</h2>)}
                            <button key={testSuite.id} onClick={showTestSuite(testSuite)} className="TestSuitesListItem">
                                <div className="TestSuitesListItemContent">
                                    <strong>{testSuite.name}</strong>
                                    <div className={'TestSuitesListItemStatus ' + testSuite.status}>
                                        <span>{testSuite.status}</span>
                                    </div>
                                    {testSuite.tests > 0 && (<><span>{testSuite.tests} test{testSuite.tests === 1 ? '' : 's'}</span></>)} {testSuite.assets.length > 0 && (<><span>{testSuite.assets.length} asset{testSuite.assets.length === 1 ? '' : 's'}</span></>)}
                                </div>
                                <span><IconArrow width={15.91} height={24.6} /></span>
                            </button>
                        </React.Fragment>
                    )
                })}
                {/* {Object.keys(testSuiteState.testSuites).length > 0 && Object.keys(testSuiteState.testSuites[testSuiteState.pageKey]).sort((a: any, b: any) => {
                    a = moment(a, 'Do MMM');
                    b = moment(b, 'Do MMM');
                    return a.diff(b);
                }).map((date: any) => {
                    return (
                        <React.Fragment key={testSuiteState.pageKey}>
                            <h2>{testSuiteState.pageKey}</h2>
                            {testSuiteState.testSuites[testSuiteState.pageKey][date].map((testSuite: any) => {
                                const time = moment(testSuite.starts_at, 'YYYY-MM-DD HH:mm').format('Do MMM');
                                return (
                                    <button key={testSuite.id} onClick={showTestSuite(testSuite)} className="TestSuitesListItem">
                                        <div className="TestSuitesListItemContent">
                                            <strong>{testSuite.client.name}</strong>
                                            <span>{time}</span> {testSuite.site && (<><span>{testSuite.site.name}</span></>)}
                                        </div>
                                        <span><IconArrow width={15.91} height={24.6} /></span>
                                    </button>
                                );
                            })}
                        </React.Fragment>
                    );
                })} */}
                {Object.keys(testSuiteState.testSuites).length > 1 && (
                    <div className="TestSuitesPagination">
                        {testSuiteState.page > 0 && (
                            <button type="button" onClick={movePrevious} className="Previous">
                                <IconArrow width={15.91} height={24.6} />
                                Previous
                            </button>
                        )}
                        {testSuiteState.page < testSuiteState.totalPages - 1 && (
                            <button type="button" onClick={moveNext} className="Next">
                                Next
                                <IconArrow width={15.91} height={24.6} />
                            </button>
                        )}
                    </div>
                )}
            </div>

        </div>
    );
}

export default TestSuiteList;
