import { createContext } from "react";
import EventHandler from "../Events";
import ApiRequest from "../../Components/ApiRequest";
import DB from "../DB";
import Authentication from "../Authentication";
import moment from "moment";

(Array.prototype as any).forEachAsync = async function (fn: any) {
    for (let t of (this as any)) { await fn(t) }
}

export default class Data {
    eventHandler: EventHandler;
    apiRequest: ApiRequest;
    db: DB;
    authentication: Authentication;

    constructor(eventHandler: EventHandler, apiRequest: ApiRequest) {
        this.eventHandler = eventHandler;
        this.apiRequest = apiRequest;
        this.db = new DB();
        this.authentication = new Authentication();

        this.eventHandler.on('network:change', this.networkChange.bind(this));

        this.eventHandler.on('profile:request', this.requestProfile.bind(this));
        this.eventHandler.on('profile:request-remote', this.requestRemoteProfile.bind(this));
        this.eventHandler.on('profile:request-local', this.requestLocalProfile.bind(this));
        this.eventHandler.on('profile:update-local', this.updateLocalProfile.bind(this));

        this.eventHandler.on('recommendations:request', this.requestRecommendations.bind(this));
        this.eventHandler.on('recommendations:request-remote', this.requestRemoteRecommendations.bind(this));
        this.eventHandler.on('recommendations:request-local', this.requestLocalRecommendations.bind(this));
        this.eventHandler.on('recommendations:update-local', this.updateLocalRecommendations.bind(this));

        this.eventHandler.on('test-suites:request', this.requestTestSuites.bind(this));
        this.eventHandler.on('test-suites:request-remote', this.requestRemoteTestSuites.bind(this));
        this.eventHandler.on('test-suites:request-local', this.requestLocalTestSuites.bind(this));
        this.eventHandler.on('test-suites-by-client:request', this.requestTestSuitesByClient.bind(this));
        this.eventHandler.on('test-suites-by-client:request-local', this.requestLocalTestSuitesByClient.bind(this));
        this.eventHandler.on('test-suites:update-local', this.updateLocalTestSuites.bind(this));

        this.eventHandler.on('test-suite:request', this.requestTestSuite.bind(this));
        this.eventHandler.on('test-suite:request-remote', this.requestRemoteTestSuite.bind(this));
        this.eventHandler.on('test-suite:request-local', this.requestLocalTestSuite.bind(this));
        this.eventHandler.on('test-suite:update-local', this.updateLocalTestSuite.bind(this));

        this.eventHandler.on('reading-values:request', this.requestReadingValues.bind(this));
        this.eventHandler.on('reading-values:request-remote', this.requestRemoteReadingValues.bind(this));
        this.eventHandler.on('reading-values:request-local', this.requestLocalReadingValues.bind(this));
        this.eventHandler.on('reading-values:update-local', this.updateLocalReadingValues.bind(this));
        this.eventHandler.on('reading-values:add-multiple', this.addReadingValues.bind(this));

        this.eventHandler.on('reading-value:request', this.requestReadingValue.bind(this));
        this.eventHandler.on('reading-value:request-remote', this.requestRemoteReadingValue.bind(this));
        this.eventHandler.on('reading-value:request-local', this.requestLocalReadingValue.bind(this));
        this.eventHandler.on('reading-value:update-local', this.updateLocalReadingValue.bind(this));

        this.eventHandler.on('actions:sync', this.syncActions.bind(this));
        this.eventHandler.on('actions:add', this.addAction.bind(this));
        this.eventHandler.on('actions:add-multiple', this.addActions.bind(this));
        this.eventHandler.on('actions:update', this.updateActions.bind(this));
        this.eventHandler.on('actions:request', this.requestActions.bind(this));

        this.eventHandler.on('logout', this.logout.bind(this));
    }

    getApi() {
        return this.apiRequest;
    }

    async networkChange(online: any) {
        if (this.authentication.getOnline() && this.authentication.check()) {
            this.eventHandler.trigger('actions:sync');
        }
    }

    async requestProfile() {
        if (this.authentication.check()) {
            this.eventHandler.trigger('profile:request-' + (this.authentication.getOnline() ? 'remote' : 'local'));
        }
    }

    async requestRemoteProfile() {
        try {
            const body = await this.apiRequest.get('/profile');
            this.eventHandler.trigger('profile:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote profile, reverting to local profile.');
            this.eventHandler.trigger('profile:request-local');
        }
    }

    async requestLocalProfile() {
        const profile = await this.db.get('profile', this.apiRequest.authentication.userId());
        this.eventHandler.trigger('profile:receive', profile, 'local');
    }

    async updateLocalProfile(profile: any, from: any) {
        if (!this.apiRequest.authentication.userId()) {
            this.apiRequest.authentication.setUserId(profile.id);
        }
        await this.db.put('profile', profile);
        this.eventHandler.trigger('profile:receive', profile, from);
    }

    async requestTestSuites() {
        if (this.authentication.check()) {
            this.eventHandler.trigger('test-suites:request-' + (this.authentication.getOnline() ? 'remote' : 'local'));
        }
    }

    async requestRemoteTestSuites() {
        try {
            const body = await this.apiRequest.get('/test-suites/upcoming');
            this.eventHandler.trigger('test-suites:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote test-suites, reverting to local test-suites.');
            this.eventHandler.trigger('test-suites:request-local');
        }
    }

    async requestLocalTestSuites() {
        const testSuites = await this.db.getAll('testSuites');
        this.eventHandler.trigger('test-suites:receive', testSuites, 'local');
    }

    async updateLocalTestSuites(testSuites: any, from: any) {
        await this.db.clear('testSuites');
        const actions = await this.db.getAll('actions', {type: 'testSuite'});
        if (actions && actions.length) {
            // Apply all pending actions.
            testSuites = await Promise.all(testSuites.map(async (testSuite: any, idx: any) => {
                const changes = await actions.filter((action: any) => Number.parseInt(action.testSuiteId) === testSuite.id);
                changes.forEach((change: any) => {
                    Object.keys(change.changes).forEach((key: any) => {
                        this.db.getOrSet(testSuite, key, change[key]);
                    });
                });
                return testSuite;
            }));
        }
        let dates: any = [],
            test_suite_ids: any = [];
        
        testSuites.forEach((testSuite: any) => {
            test_suite_ids.push(testSuite.id);
            Array.prototype.push.apply(dates, Object.keys(testSuite.scheduled_dates));
        });

        if (test_suite_ids.length) {
            this.eventHandler.trigger('reading-values:request', {
                test_suite_ids
            }, 'remote');
        }
        await this.db.bulkPut('testSuites', testSuites);
        this.eventHandler.trigger('test-suites:receive', testSuites, from);
    }

    async requestTestSuite(testSuiteId: any) {
        if (this.authentication.check()) {
            this.eventHandler.trigger('test-suite:request-local', testSuiteId);
        }
    }

    async requestTestSuitesByClient(clientId: any) {
        if (this.authentication.check()) {
            this.eventHandler.trigger('test-suites-by-client:request-local', clientId);
        }
    }

    async requestRemoteTestSuite(testSuiteId: any) {
        try {
            const body = await this.apiRequest.get('/test-suites/' + testSuiteId);
            this.eventHandler.trigger('test-suite:update-local', testSuiteId, body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote test-suite, reverting to local test-suites.');
            this.eventHandler.trigger('test-suite:request-local', testSuiteId);

        }
    }

    async requestLocalTestSuite(testSuiteId: any) {
        const testSuite = await this.db.get('testSuites', {id: Number.parseInt(testSuiteId)});
        this.eventHandler.trigger('test-suite:receive:' + testSuiteId, testSuite);
    }

    async requestLocalTestSuitesByClient(clientId: any) {
        const testSuites = await this.db.getAll('testSuites', {'client.id': Number.parseInt(clientId)});
        this.eventHandler.trigger('test-suites-by-client:receive:' + clientId, testSuites);
    }

    async updateLocalTestSuite(testSuiteId: any, changes: any, createAction: boolean = false) {
        await this.db.update('testSuites', Number.parseInt(testSuiteId), changes);
        if (createAction) {
            await this.db.add('actions', {type: 'testSuite', testSuiteId, changes});
            this.eventHandler.trigger('actions:sync');
        }
        this.eventHandler.trigger('test-suite:request-local', testSuiteId);
    }

    async requestReadingValues(query: any = {}, src: any = 'local', key: any = '') {
        if (src === 'remote' && !this.authentication.getOnline()) {
            src = 'local';
        }
        if (this.authentication.check()) {
            this.eventHandler.trigger('reading-values:request-' + src, query, key);
        }
    }

    async requestRemoteReadingValues(query: any = {}, key: any = '') {
        try {
            const body = await this.apiRequest.get('/reading-values', query);
            let readings = Object.values(body.data.reduce((acc: any, item: any) => {
                let key: any = [item.asset_id, item.date, item.test_configuration_id, item.test_suite_id, item.test_suite_test_asset_id].join('-');
                acc[key] = item;
                return acc;
            }, {}));
            this.eventHandler.trigger('reading-values:update-local', readings, 'remote', key);
        } catch (e) {
            console.error('Failed to fetch remote reading-values, reverting to local reading-values.');
            this.eventHandler.trigger('reading-values:request-local', key);
        }
    }

    async requestLocalReadingValues(query: any = {}, key: any = '') {
        const readingValues = await this.db.getAll('readingValues', query);
        this.eventHandler.trigger('reading-values:receive', readingValues, 'local', key);
    }

    async updateLocalReadingValues(readingValues: any, from: any, key: any = '') {
        await this.db.clear('readingValues');
        const actions = await this.db.getAll('actions', {type: 'readingValue'});
        if (actions && actions.length) {
            // Apply all pending actions.
            readingValues = await Promise.all(readingValues.map(async (readingValue: any, idx: any) => {
                const changes = await actions.filter((action: any) => 
                    Number.parseInt(action.test_suite_id) === readingValue.id &&
                    action.date === readingValue.date &&
                    Number.parseInt(action.asset_id) === readingValue.asset_id &&
                    Number.parseInt(action.test_suite_test_asset_id) === readingValue.test_suite_test_asset_id &&
                    Number.parseInt(action.test_configuration_id) === readingValue.test_configuration_id
                );
                changes.forEach((change: any) => {
                    Object.keys(change.changes).forEach((key: any) => {
                        this.db.getOrSet(readingValue, key, change[key]);
                    });
                });
                return readingValue;
            }));
        }
        await this.db.bulkPut('readingValues', readingValues);
        this.eventHandler.trigger('reading-values:receive', readingValues, from, key);
    }

    async addReadingValues(readingValues: any, from: any) {
        await readingValues.forEach(async (value: any) => {
            const rows: any = this.db.getAll('readingValues', {
                test_suite_id: value.test_suite_id,
                date: value.date,
                asset_id: value.asset_id
            });
            if (rows.length) {
                await this.db.bulkDelete('readingValues', rows.map((row: any) => row.id));
            }
            await this.db.add('readingValues', value);
            await this.db.add('actions', {
                type: 'readingValue',
                created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                changes: value,
                completed: false,
                competion_comments: ''
            });
        });
    }

    async requestReadingValue(readingValueId: any) {
        if (this.authentication.check()) {
            this.eventHandler.trigger('reading-value:request-local', readingValueId);
        }
    }

    async requestRemoteReadingValue(readingValueId: any) {
        try {
            const body = await this.apiRequest.get('/reading-values/' + readingValueId);
            this.eventHandler.trigger('reading-value:update-local', readingValueId, body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote reading-value, reverting to local reading-values.');
            this.eventHandler.trigger('reading-value:request-local', readingValueId);

        }
    }

    async requestLocalReadingValue(readingValueId: any) {
        const readingValue = await this.db.get('readingValues', {id: Number.parseInt(readingValueId)});
        this.eventHandler.trigger('reading-value:receive:' + readingValueId, readingValue);
    }

    async updateLocalReadingValue(readingValueId: any, changes: any, createAction: boolean = false) {
        await this.db.update('readingValues', Number.parseInt(readingValueId), changes);
        if (createAction) {
            await this.db.add('actions', {type: 'readingValue', readingValueId, changes});
            this.eventHandler.trigger('actions:sync');
        }
        this.eventHandler.trigger('reading-value:request-local', readingValueId);
    }

    async requestRecommendations() {
        if (this.authentication.check()) {
            this.eventHandler.trigger('recommendations:request-' + (this.authentication.getOnline() ? 'remote' : 'local'));
        }
    }

    async requestRemoteRecommendations() {
        try {
            const body = await this.apiRequest.get('/recommendations');
            this.eventHandler.trigger('recommendations:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote test-suites, reverting to local test-suites.');
            this.eventHandler.trigger('test-suites:request-local');
        }
    }

    async requestLocalRecommendations() {
        const recommendations = await this.db.getAll('recommendations');
        this.eventHandler.trigger('recommendations:receive', recommendations, 'local');
    }

    async updateLocalRecommendations(recommendations: any, source: any) {
        await this.db.clear('recommendations');
        await this.db.bulkPut('recommendations', recommendations);
        this.eventHandler.trigger('recommendations:receive', recommendations);
    }

    async syncActions() {
        if (this.authentication.getOnline() && this.authentication.check()) {
            const actions = await this.db.getAll('actions');
            if (actions.length) {
                await actions.forEachAsync(async (action: any) => {
                    try {
                        switch (action.type) {
                            // case 'testSuite':
                            //     await this.apiRequest.put('/test-suites/' + action.changes.test_suite_id, action.changes);
                            //     break;
                            case 'readingValue':
                                if (!action.completed) {
                                    return true;
                                }
                                await this.apiRequest.post('/reading-values', {...action.changes, completed_at: action.completed_at, completion_comment: action.completion_comment});
                                break;
                            default:
                                return true;
                        }
                        await this.db.delete('actions', Number.parseInt(action.id));
                    } catch (e) {
                        console.error('Failed to sync action: ' + action.id);
                    }
                });
            }
        }
        this.eventHandler.trigger('test-suites:request');
        this.eventHandler.trigger('recommendations:request');
    }

    async addAction(action: any) {
        await this.db.add('actions', action);
    }

    async addActions(actions: any) {
        await this.db.bulkAdd('actions', actions);
    }

    async updateActions(actions: any) {
        actions.forEach(async (action: any) => {
            await this.db.delete('actions', action.id);
        })
        await this.db.bulkAdd('actions', actions.map((action: any) => {
            if ('id' in action) {
                delete action.id;
            }
            return action;
        }));
    }

    async requestActions(query: any = {}) {
        const result = await this.db.getAll('actions', query);
        this.eventHandler.trigger('actions:receive', result);
    }

    async logout() {
        this.apiRequest.authentication.logout();
        await this.db.flush();
        await this.db.init();
    }
}

export const DataContext = createContext(null as any);