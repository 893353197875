import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../Contexts/Authentication';
import { registerHandlers, unRegisterHandlers } from '../Components/ErrorHandler';
import Modal from '../Components/Modal';
import { DataContext } from '../Contexts/Data';

function Setup() {
    const [setupState, setSetupState] = useState({
        busy: true,
        error: false as any,
        continue: false,
        location: false,
        notification: false
    });

    const navigation = useRef(useNavigate());
    const authentication = useContext(AuthenticationContext);
    const loading = useRef(true);
    const dataLayer = useContext(DataContext);
    const apiRequest = dataLayer.getApi();

    const showError = useCallback((e: any) => {
        setSetupState({ ...setupState, busy: false, error: true });
    }, [setupState]);

    useEffect(() => {
      const handle = registerHandlers(showError);
      return () => {
        unRegisterHandlers(handle);
      }
    }, [showError]);

    useEffect(() => {
        if (authentication.hasPermissions()) {
            navigation.current('/');
            return;
        }
        if (loading.current) {
            loading.current = false;
        }
    }, [setSetupState, setupState, apiRequest, loading, showError, authentication]);

    const requestLocationAccess = () => {
        navigator.geolocation.getCurrentPosition(() => {
            setSetupState((state) => ({...state, continue: true, location: true}));
        }, (error: any) => {
            setSetupState((state) => ({...state, continue: false, error: {
                title: 'Permission Denied',
                message: 'Permission to access your location has been denied.'
            }}));
        });
    }

    const proceed = () => {
        authentication.setPermissions({
            location: true
        });
        navigation.current('/');
    }

    return (
        <div className="Setup">
            {setupState.error && (
                <Modal onDismiss={() => setSetupState({ ...setupState, error: false })} title="Setup Failed">There was a problem processing your request.<br /><br />Please try again.</Modal>
            )}
            <h1>Permissions</h1>
            <p>To proceed with using the Clearflow app, you must provide the required permissions below.</p>
            <h2>Location</h2>
            <p>For safeguarding and reporting purposes, we require access go your current position whilst using the app.</p>
            <button type="button" className="PermissionButton" disabled={setupState.location} onClick={requestLocationAccess}>{(setupState.location ? 'Location Access Granted' : 'Allow Location')}</button>
            <button type="button" className="Button" onClick={proceed} disabled={!setupState.continue}>Continue</button>
        </div>
    );
}

export default Setup;
