import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../../Components/Busy';
import { ReactComponent as IconArrow } from '../../../assets/images/arrow.svg';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../../Contexts/Events';
import Modal from '../../../Components/Modal';

function TestSuiteTestView() {
    const [testSuiteState, setTestSuiteState] = useState({
        busy: true,
        updating: false,
        errors: {} as any,
        totals: {} as any,
        testSuites: [] as any,
        actions: [] as any,
        skipAll: false,
        historic: {} as any,
        historyVisible: false as any,
        configurations: [] as any,
        recommendations: [] as any,
        previous_date: false as any,
        failed_configuration: false as any,
        samples: [] as any,
        status: 'pending'
    });

    const loading = useRef(true);
    const { clientId, testSuiteDate, siteId, buildingId, assetId } = useParams();
    const navigate = useNavigate();
    const eventHandler = useContext(EventContext);
    const formRef = React.createRef<HTMLFormElement>();

    useEffect(() => {
        const recommendationsReceived = (recommendations: any) => {
            setTestSuiteState(state => ({ ...state, recommendations }));
        };

        const actionsReceived = (actions: any) => {
            actions = Object.values(actions.reduce((acc: any, action: any) => {
                let key = [action.test_suite_id, action.date].join('-');
                acc[key] = action;
                return acc;
            }, {}));
            setTestSuiteState({...testSuiteState, actions});

            eventHandler.trigger('reading-values:request', {
                test_suite_id: Number.parseInt(clientId ?? ''),
                date: testSuiteDate,
            }, 'remote', 'current');
            eventHandler.trigger('reading-values:request', {
                test_suite_id: Number.parseInt(clientId ?? ''),
            }, 'local', 'historic');
        }

        const readingValuesReceived = (values: any, from: any, source: any) => {
            values = Object.values(values.reduce((acc: any, value: any) => {
                let key = [value.test_suite_test_id, value.test_configuration_id, value.test_suite_test_asset_id].join('-');
                acc[key] = value;
                return acc;
            }, {}));

            if (source === 'historic') {

                values.forEach((value: any) => {
                    testSuiteState.configurations.forEach((configuration: any) => {
                        if (value.status === 'fail' && value.date === configuration.last_date && value.test_configuration_id === configuration.test_configuration_id && value.test_suite_test_asset_id === configuration.test_suite_test_asset_id) {
                            configuration.last_reading = value;
                        }
                    });
                });

                setTestSuiteState(state => ({ ...state, historic: values, configurations: testSuiteState.configurations }));
                return;
            }

            let totals = {} as any;

            testSuiteState.testSuites.forEach((suite: any) => {
                totals[suite.id] = {
                    total: 0,
                    completed: 0,
                    local_total: 0,
                    local_completed: 0,
                    status: 'pending'
                };
                suite.tests.forEach((test: any) => {
                    test.assets.forEach((asset: any) => {
                        totals[suite.id].total += asset.configurations.length;
                        if (asset.asset_id === Number.parseInt(assetId ?? '')) {
                            totals[suite.id].local_total += asset.configurations.length;
                        }
                    });
                });
            });

            values.forEach((value: any) => {
                if (value.date === testSuiteDate) {
                    testSuiteState.samples.tests.forEach((test: any) => {
                        if (value.test_suite_test_id === test.id) {
                            test.assets.forEach((asset: any) => {
                                if (value.test_suite_test_asset_id === asset.test_suite_asset_id) {
                                    asset.configurations.forEach((configuration: any) => {
                                        if (configuration.test_configuration_id === value.test_configuration_id) {
                                            if (asset.asset_id === Number.parseInt(assetId ?? '')) {
                                                configuration.status = value.status;
                                                configuration.value = value.value;
                                                configuration.taken_at = value.taken_at;
                                                configuration.comments = value.comments;
                                                configuration.from_value = true;
                                                testSuiteState.samples.status = value.status;
                                                totals[value.test_suite_id].local_completed++;
                                                if (configuration.name === 'Sample Temperature') {
                                                    testSuiteState.samples.value = value.value;
                                                    testSuiteState.samples.comments = value.comments;
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                    testSuiteState.configurations.forEach((configuration: any) => {
                        if (value.test_suite_test_asset_id === configuration.test_suite_test_asset_id && value.test_configuration_id === configuration.test_configuration_id) {
                            configuration.status = value.status;
                            configuration.value = value.value;
                            configuration.taken_at = value.taken_at;
                            configuration.comments = value.comments;
                            configuration.from_value = true;
                            totals[value.test_suite_id].local_completed++;
                        }
                    });
                }
            });

            testSuiteState.actions.forEach((value: any) => {
                if (value.changes.date === testSuiteDate) {
                    testSuiteState.samples.tests.forEach((test: any) => {
                        if (value.changes.test_suite_test_id === test.id) {
                            test.assets.forEach((asset: any) => {
                                if (value.changes.test_suite_test_asset_id === asset.test_suite_asset_id) {
                                    asset.configurations.forEach((configuration: any) => {
                                        if (configuration.test_configuration_id === value.changes.test_configuration_id) {
                                            if (asset.asset_id === Number.parseInt(assetId ?? '')) {
                                                configuration.status = value.changes.status;
                                                configuration.value = value.changes.value;
                                                configuration.taken_at = value.changes.taken_at;
                                                configuration.comments = value.changes.comments;
                                                configuration.from_value = true;
                                                testSuiteState.samples.status = value.changes.status;
                                                totals[value.changes.test_suite_id].local_completed++;
                                                if (configuration.name === 'Sample Temperature') {
                                                    testSuiteState.samples.value = value.changes.value;
                                                    testSuiteState.samples.comments = value.changes.comments;
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                    testSuiteState.configurations.forEach((configuration: any) => {
                        if (value.changes.test_suite_test_asset_id === configuration.test_suite_test_asset_id && value.changes.test_configuration_id === configuration.test_configuration_id) {
                            configuration.status = value.changes.status;
                            configuration.value = value.changes.value;
                            configuration.taken_at = value.changes.taken_at;
                            configuration.comments = value.changes.comments;
                            configuration.from_value = true;
                            totals[value.changes.test_suite_id].local_completed++;
                        }
                    });
                }
            });

            let status = 'complete';
            Object.keys(totals).forEach((key: any) => {
                var item = totals[key];
                if (item.completed > 0 && item.completed < item.total) {
                    item.status = 'started';
                } else if (item.completed === item.total) {
                    item.status = 'complete';
                }

                if (item.status === 'pending') {
                    status = 'pending';
                } else if (item.status === 'started') {
                    status = 'started';
                }
            });

            setTestSuiteState(state => ({ ...testSuiteState, totals, status }));
        }

        const testSuiteReceived = (testSuites: any = undefined) => {
            if (!testSuites) {
                navigate('/', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested test suite could not be loaded.'
                        }
                    }
                });
                return;
            }

            const configurations = testSuites.reduce(function (acc: any, item: any) {
                return item.tests.reduce((_acc: any, test: any) => {
                    test.assets.forEach((asset: any) => {
                        if (assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                            asset.configurations.forEach((configuration: any) => {
                                if (!(configuration.name === 'Sample Taken' || configuration.name === 'Sample Temperature')) {
                                    _acc.push({ ...configuration, test_suite_id: item.id, test_id: test.id, last_date: (item.reading_frequency === 'none' ? false : moment().subtract(item.reading_interval, item.reading_frequency).startOf(item.reading_frequency).format('YYYY-MM-DD')) });
                                }
                            });
                        }
                    });
                    return _acc;
                }, acc);
            }, []);

            const samples = testSuites.reduce(function (acc: any, item: any) {
                return item.tests.reduce((_acc: any, test: any) => {
                    test.assets.forEach((asset: any) => {
                        if (assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                            let hasSample: any = false;
                            asset.configurations.forEach((configuration: any) => {
                                if ((configuration.name === 'Sample Taken' || configuration.name === 'Sample Temperature')) {
                                    hasSample = true;
                                }
                            });
                            if (hasSample) {
                                acc.push({ ...test, test_suite_id: item.id, system: item.system, last_date: (item.reading_frequency === 'none' ? false : moment().subtract(item.reading_interval, item.reading_frequency).startOf(item.reading_frequency).format('YYYY-MM-DD')) });
                            }
                        }
                    });
                    return _acc;
                }, acc);
            }, []);

            const sample_systems = Object.values(samples.reduce((acc: any, item: any) => {
                if ('system' in item && item.system && !(item.system.id in acc)) {
                    acc[item.system.id] = { ...item.system, count: 0 };
                }
                acc[item.system.id].count++;
                return acc;
            }, {}));

            setTestSuiteState(state => ({
                ...state, updating: false, busy: false, testSuites, samples: {
                    tests: samples,
                    systems: sample_systems,
                    open: false,
                    taken_at: '',
                    comments: '',
                    value: '',
                    status: 'pending'
                }, configurations
            }));

            eventHandler.trigger('recommendations:request');
            eventHandler.trigger('actions:request', {
                type: 'readingValue'
            });
        }

        if (loading.current) {
            eventHandler.trigger('test-suites-by-client:request', clientId);
            loading.current = false;
        }

        eventHandler.on('test-suites-by-client:receive:' + clientId, testSuiteReceived);
        eventHandler.on('recommendations:receive', recommendationsReceived);
        eventHandler.on('reading-values:receive', readingValuesReceived);
        eventHandler.on('actions:receive', actionsReceived);
        return () => {
            eventHandler.off('test-suites-by-client:receive:' + clientId, testSuiteReceived);
            eventHandler.off('recommendations:receive', recommendationsReceived);
            eventHandler.off('reading-values:receive', readingValuesReceived);
            eventHandler.off('actions:receive', actionsReceived);
        }
    }, [testSuiteState, clientId, testSuiteDate, assetId, navigate, eventHandler]);

    const client = (testSuiteState.busy || testSuiteState.testSuites.length <= 0 ? false : testSuiteState.testSuites.reduce((acc: any, item: any) => {
        return item.client;
    }, false));

    const site = (siteId !== undefined ? testSuiteState.testSuites.reduce(function (acc: any, item: any) {
        item.tests.forEach((test: any) => {
            test.assets.forEach((asset: any) => {
                if (asset.site && Number.parseInt(asset.site.id) === Number.parseInt(siteId)) {
                    acc = asset.site;
                    return true;
                }
            });
            if (acc) {
                return true;
            }
        });
        return acc;
    }, false) : undefined);

    const building = (buildingId !== undefined ? testSuiteState.testSuites.reduce(function (acc: any, item: any) {
        item.tests.forEach((test: any) => {
            test.assets.forEach((asset: any) => {
                if (asset.building && Number.parseInt(asset.building.id) === Number.parseInt(buildingId)) {
                    acc = asset.building;
                    return true;
                }
            });
            if (acc) {
                return true;
            }
        });
        return acc;
    }, false) : undefined);

    const asset = (testSuiteState.busy ? false : testSuiteState.testSuites.reduce((acc: any, item: any) => {
        return item.tests.reduce((_acc: any, test: any) => {
            test.assets.forEach((asset: any) => {
                if (!_acc && assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                    _acc = asset;
                }
            });
            return _acc;
        }, acc);
    }, false));

    const toggleConfiguration = (configuration_id: any) => {
        return () => {
            testSuiteState.samples.open = false;
            let isOpen = !!testSuiteState.configurations[configuration_id].open;
            Object.keys(testSuiteState.configurations).forEach((id: any) => {
                testSuiteState.configurations[id].open = false;
            });
            if (!isOpen) {
                testSuiteState.configurations[configuration_id].open = true;
            }
            setTestSuiteState({ ...testSuiteState });
        };
    };

    const toggleSamples = () => {
        return () => {
            testSuiteState.samples.open = !!!testSuiteState.samples.open;
            Object.keys(testSuiteState.configurations).forEach((id: any) => {
                testSuiteState.configurations[id].open = false;
            });
            setTestSuiteState({ ...testSuiteState });
        };
    };

    const setConfigurationStatus = (configuration_id: any, status: any) => {
        return () => {
            testSuiteState.configurations[configuration_id].status = status;
            setTestSuiteState({ ...testSuiteState });
        };
    };

    const setConfigurationRecommendation = (configuration_id: any) => {
        return (e: any) => {
            if (!('comments' in testSuiteState.configurations[configuration_id])) {
                testSuiteState.configurations[configuration_id].comments = '';
            }
            testSuiteState.configurations[configuration_id].comments += "\n" + e.target.options[e.target.selectedIndex].value;
            testSuiteState.configurations[configuration_id].comments = testSuiteState.configurations[configuration_id].comments.trim();
            setTestSuiteState({ ...testSuiteState });
            e.target.selectedIndex = 0;
        };
    };

    const setConfigurationPriority = (configuration_id: any) => {
        return (e: any) => {
            testSuiteState.configurations[configuration_id].priority = e.target.options[e.target.selectedIndex].value;
            setTestSuiteState({ ...testSuiteState });
        };
    };

    const setSampleStatus = (status: any) => {
        return () => {
            testSuiteState.samples.status = status;
            testSuiteState.samples.tests.forEach((test: any) => {
                test.assets.forEach((asset: any) => {
                    if (Number.parseInt(asset.asset_id) === Number.parseInt(assetId ?? '')) {
                        asset.configurations.forEach((configuration: any) => {
                            configuration.status = status;
                            configuration.taken_at = moment().format('YYYY-MM-DD HH:mm:ss');
                        });
                    }
                });
            });
            setTestSuiteState({ ...testSuiteState });
        };
    };

    const checkValue = (e: any, configuration_id: any) => {
        let configuration = testSuiteState.configurations.filter((c: any) => c.id === configuration_id).pop(),
            value = e.target.value,
            status = configuration.status ?? 'pending';

        if (configuration.pass_fail) {
            let pass = true,
                priority: any = 'low',
                tmp_priority = 1;

            switch (configuration.type) {
                case 'lt':
                    pass = value < configuration.configuration.value;
                    break;
                case 'lte':
                    pass = value <= configuration.configuration.value;
                    break;
                case 'gt':
                    pass = value > configuration.configuration.value;
                    break;
                case 'gte':
                    pass = value >= configuration.configuration.value;
                    break;
                case 'between':
                    pass = (value >= configuration.configuration.min && value <= configuration.configuration.max);
                    break;
            }

            if (!pass) {
                let tolerance_map: any = {'none': 0, 'low': 1, 'medium': 2, 'high': 3};
                Object.keys(tolerance_map).forEach((key: any) => {
                    let tmp = key + '_tolerance';
                    if (tmp in configuration.configuration && configuration.configuration[tmp]) {
                        let diff = Math.abs(configuration.configuration.value - value);
                        if (diff >= configuration.configuration[tmp] && tolerance_map[key] > tmp_priority) {
                            priority = tmp_priority;
                        }
                    }
                });
                configuration.priority = priority;
            }

            status = (pass ? 'pass' : 'fail');
        } else {
            status = 'actioned';
        }

        testSuiteState.configurations.forEach((configuration: any) => {
            if (configuration.id === configuration_id) {
                configuration.status = status;
                configuration.value = value;
                configuration.taken_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }
        });

        setTestSuiteState({ ...testSuiteState });
    }

    const checkSampleValue = (e: any, system_id: any) => {
        let value: any = e.target.value;
        testSuiteState.samples.tests.forEach((test: any) => {
            if (test.system.id === system_id) {
                test.assets.forEach((asset: any) => {
                    if (assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                        asset.configurations.forEach((configuration: any) => {
                            if (configuration.name === 'Sample Taken') {
                                if (value) {
                                    configuration.status = 'actioned';
                                    configuration.value = configuration.value ?? moment().format('YYYY-MM-DD HH:mm:ss');
                                    configuration.taken_at = moment().format('YYYY-MM-DD HH:mm:ss');
                                } else {
                                    configuration.status = 'pending';
                                    configuration.value = undefined;
                                    configuration.taken_at = undefined;
                                }
                            }
                            if (configuration.name === 'Sample Temperature') {
                                if (value) {
                                    configuration.status = 'actioned';
                                    configuration.value = configuration.value ?? moment().format('YYYY-MM-DD HH:mm:ss');
                                    configuration.taken_at = moment().format('YYYY-MM-DD HH:mm:ss');
                                } else {
                                    configuration.status = 'pending';
                                    configuration.value = undefined;
                                    configuration.taken_at = undefined;
                                }
                            }
                        });
                    }
                });
            } 
        });

        let total: any = 0,
            actioned: any = 0;

        testSuiteState.samples.tests.forEach((test: any) => {
            test.assets.forEach((asset: any) => {
                if (assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                    asset.configurations.forEach((configuration: any) => {
                        if (configuration.name === 'Sample Taken') {
                            total++;
                            if (configuration.status === 'actioned') {
                                actioned++;
                            }
                        }
                        if (configuration.name === 'Sample Temperature') {
                            total++;
                            if (configuration.status === 'actioned') {
                                actioned++;
                            }
                        }
                    });
                }
            });
        });

        testSuiteState.samples.status = 'pending';
        if (actioned >= total) {
            testSuiteState.samples.status = 'actioned';
        }

        setTestSuiteState({ ...testSuiteState });
    }

    const setSampleComments = (e: any) => {
        testSuiteState.samples.comments = e.target.value;
        testSuiteState.samples.tests.forEach((test: any) => {
            test.assets.forEach((asset: any) => {
                if (assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                    asset.configurations.forEach((configuration: any) => {
                        if (['Sample Taken', 'Sample Temperature'].indexOf(configuration.name) !== -1) {
                            configuration.comments = e.target.value;
                        }
                    });
                }
            });
        });
        setTestSuiteState({ ...testSuiteState });
    }

    const setConfigurationComments = (e: any, configuration_id: any) => {
        testSuiteState.configurations[configuration_id].comments = e.target.value;
        setTestSuiteState({ ...testSuiteState });
    }

    const saveAsset = (status: any = 'pass') => {
        return () => {
            let completed: any = {},
                actions: any = [],
                errors: any = {};

            if ('samples' in testSuiteState && testSuiteState.samples.tests.length && testSuiteState.samples.status !== 'pending') {
                testSuiteState.samples.tests.forEach((test: any) => {
                    test.assets.forEach((asset: any) => {
                        if (Number.parseInt(asset.asset_id) === Number.parseInt(assetId ?? '')) {
                            asset.configurations.forEach((configuration: any) => {
                                if (configuration.status === 'pending') {
                                    return true;
                                }
                                if (configuration.status === 'actioned' && configuration.value.length === 0) {
                                    errors['sample_value_' + test.system.id] = 'A sample temperature is required.';
                                    testSuiteState.samples.open = true;
                                    return true;
                                }
                                if (configuration.status === 'skip' && (!('comments' in configuration) || !configuration.comments || configuration.comments.length === 0)) {
                                    errors.sample_comments = 'A comment/reason must be provided when skipping.';
                                    testSuiteState.samples.open = true;
                                    return true;
                                }
                                if (configuration.status === 'fail' && (!('comments' in configuration) || !configuration.comments || configuration.comments.length === 0)) {
                                    errors.sample_comments = 'A comment must be provided for failing tests.';
                                    testSuiteState.samples.open = true;
                                    return true;
                                }

                                if (!('from_action' in configuration)) {
                                    if (!(configuration.test_suite_id in completed)) {
                                        completed[configuration.test_suite_id] = 0;
                                    }
                                    completed[configuration.test_suite_id]++;
                                }

                                actions.push({
                                    test_suite_id: test.test_suite_id,
                                    date: testSuiteDate,
                                    asset_id: Number.parseInt(assetId ?? ''),
                                    test_suite_test_id: test.id,
                                    test_suite_test_asset_id: Number.parseInt(configuration.test_suite_test_asset_id ?? ''),
                                    test_configuration_id: Number.parseInt(configuration.test_configuration_id ?? ''),
                                    status: configuration.status,
                                    value: configuration.value,
                                    comments: configuration.comments,
                                    priority: 'none',
                                    taken_at: configuration.taken_at
                                });
                            });
                        }
                    });
                })
            }

            testSuiteState.configurations.forEach((configuration: any) => {
                if (!('status' in configuration) || configuration.status === 'pending') {
                    return true;
                }
                if (configuration.status === 'skip' && (!('comments' in configuration) || configuration.comments.length === 0)) {
                    errors['configuration_' + configuration.id + '_comments'] = 'A comment/reason must be provided when skipping.';
                    configuration.open = true;
                    return true;
                }
                if (['skip', 'pending'].indexOf(configuration.status) === -1 && (!('value' in configuration) || configuration.value.length === 0)) {
                    errors['configuration_' + configuration.id + '_value'] = 'A value is required.';
                    configuration.open = true;
                    return true;
                }
                if (['fail'].indexOf(configuration.status) > -1 && (!('comments' in configuration) || !configuration.comments || configuration.comments.length === 0)) {
                    errors['configuration_' + configuration.id + '_comments'] = 'A comment must be provided for failing tests.';
                    configuration.open = true;
                    return true;
                }

                if (!('from_action' in configuration)) {
                    if (!(configuration.test_suite_id in completed)) {
                        completed[configuration.test_suite_id] = 0;
                    }
                    completed[configuration.test_suite_id]++;
                }

                actions.push({
                    test_suite_id: configuration.test_suite_id,
                    date: testSuiteDate,
                    asset_id: Number.parseInt(assetId ?? ''),
                    test_suite_test_id: configuration.test_id,
                    test_suite_test_asset_id: Number.parseInt(configuration.test_suite_test_asset_id ?? ''),
                    test_configuration_id: Number.parseInt(configuration.test_configuration_id ?? ''),
                    status: configuration.status,
                    value: configuration.value,
                    comments: configuration.comments,
                    priority: ('priority' in configuration && configuration.priority ? configuration.priority : 'none'),
                    taken_at: configuration.taken_at
                });
            });

            testSuiteState.errors = errors;
            setTestSuiteState({ ...testSuiteState });
            if (Object.keys(errors).length === 0) {
                Object.keys(testSuiteState.totals).forEach((key: any) => {
                    let item = testSuiteState.totals[key],
                        c_completed = item.c_completed,
                        c_total = item.c_total;

                    c_completed += completed[key];

                    status = 'pending';
                    if (c_completed > 0 && c_completed < c_total) {
                        status = 'started';
                    } else if (c_completed === c_total) {
                        status = 'complete';
                    }

                    eventHandler.trigger('actions:add', {
                        type: 'testSuite',
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                        changes: {
                            test_suite_id: Number.parseInt(key ?? ''),
                            date: testSuiteDate,
                            status
                        }
                    });

                });
                eventHandler.trigger('reading-values:add-multiple', actions, true);
                // eventHandler.trigger('actions:add-multiple', actions);
                eventHandler.trigger('test-suite:request', clientId);
                navigate('/test-suites/view/' + clientId + '/' + testSuiteDate + '/site/' + siteId + '/building/' + buildingId, {
                    state: {
                        notice: {
                            title: 'Asset Readings Saved',
                            message: 'Your asset readings have been successfully saved.'
                        }
                    }
                });
            }
        };
    };

    const getRecommendations = (test_id: any, test_configuration_id: any) => {
        return testSuiteState.recommendations.reduce((acc: any, recommendation: any) => {
            if (
                (!recommendation.test_id || (recommendation.test_id && Number.parseInt(recommendation.test_id) === Number.parseInt(test_id))) &&
                (!recommendation.test_configuration_id || (recommendation.test_configuration_id && Number.parseInt(recommendation.test_configuration_id) === Number.parseInt(test_configuration_id)))
            ) {
                acc.push(recommendation);
            }
            return acc;
        }, []);
    }

    const skipAsset = (status: any = 'skip') => {
        return () => {
            testSuiteState.skipAll = true;
            setTestSuiteState({ ...testSuiteState });
        };
    };

    const setSkipComments = (e: any) => {
        if ('samples' in testSuiteState && testSuiteState.samples.status === 'pending') {
            testSuiteState.samples.comments = e.target.value;
            testSuiteState.samples.status = 'skip';
            testSuiteState.samples.open = false;
            testSuiteState.samples.tests.forEach((test: any) => {
                test.assets.forEach((asset: any) => {
                    if (assetId && Number.parseInt(asset.asset_id) === Number.parseInt(assetId)) {
                        asset.configurations.forEach((configuration: any) => {
                            if (['Sample Taken', 'Sample Temperature'].indexOf(configuration.name) !== -1) {
                                configuration.comments = e.target.value;
                                configuration.status = 'skip';
                                configuration.taken_at = moment().format('YYYY-MM-DD HH:mm:ss');
                            }
                        });
                    }
                });
            });
        }
        testSuiteState.configurations.forEach((configuration: any) => {
            if (!('status' in configuration) || configuration.status === 'pending') {
                configuration.comments = e.target.value;
                configuration.status = 'skip';
                configuration.taken_at = moment().format('YYYY-MM-DD HH:mm:ss');
                configuration.open = false;
            }
        });
        setTestSuiteState({ ...testSuiteState });
    };

    const closeSkip = () => {
        testSuiteState.skipAll = false;
        setTestSuiteState({ ...testSuiteState });
    }

    return (
        <>
            <form ref={formRef} method="post" className={`TestSuiteTestView ${testSuiteState.busy ? 'is--busy' : ''}`}>
                {testSuiteState.busy && (
                    <Busy />
                )}
                {!testSuiteState.busy && (
                    <>
                        <Link className="Back" to={'/test-suites/view/' + clientId + '/' + testSuiteDate + '/site/' + siteId}>
                            <span>
                                <IconArrow width={15.91} height={24.6} />
                            </span>
                            Back to site
                        </Link>
                        {testSuiteState.status === 'complete' && (
                            <p className="TestSuiteTestCompleted">All tasks on this asset have been completed.</p>
                        )}
                        <div className="TestSuiteTestViewDetail">
                            <h2>{moment(testSuiteDate, 'YYYY-MM-DD').format('MMMM YYYY')}</h2>
                            <h1>{client.name}</h1>
                            {site && site.name !== client.name && (
                                <p>{site.name}</p>
                            )}
                            {building && building.name !== client.name && (
                                <p>{building.name}</p>
                            )}
                            <h3>{asset.name}</h3>
                        </div>
                        <p><strong>Tasks</strong></p>
                        {testSuiteState.samples.tests.length > 0 && (
                            <div className={'TestSuiteTestAssetConfiguration ' + testSuiteState.samples.status + ' ' + (!!testSuiteState.samples.open ? 'open' : '')} key="samples">
                                <div onClick={toggleSamples()}>
                                    <strong>Samples Required</strong>
                                </div>
                                <span onClick={toggleSamples()}><IconArrow width={15.91} height={24.6} /></span>
                                <div className="content">
                                    <div className="content-inner">
                                        {testSuiteState.samples.systems.map((system: any) => {
                                            return (
                                                <React.Fragment key={system.id}>
                                                    <p>{system.count}x <strong>{system.name}</strong> sample{(system.count === 1 ? '' : 's')}.</p>
                                                    <div className={'input-unit' + ('sample_value_' + system.id in testSuiteState.errors ? ' HasError' : '')}>
                                                        <input type="number" defaultValue={('value_' + system.id in testSuiteState.samples ? testSuiteState.samples['value_' + system.id] : '')} onChange={(e) => checkSampleValue(e, system.id)} step="0.01" name={'configuration[sample][value_' + system.id + ']'} />
                                                        <span>°C</span>
                                                    </div>
                                                    {'sample_value_' + system.id in testSuiteState.errors && (
                                                        <div className="FieldError">{testSuiteState.errors.sample_value}</div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                        <div className="ConfigurationActions">
                                            <div className="ButtonGroup">
                                                {/* <button type="button" className={'pass' + (testSuiteState.samples.status === 'actioned' ? ' active' : '')} onClick={setSampleStatus('actioned')}>Actioned</button> */}
                                                <button type="button" className={'skip' + (testSuiteState.samples.status === 'skip' ? ' active' : '')} onClick={setSampleStatus('skip')}>Skip</button>
                                            </div>
                                            <div className="ConfigurationReason">
                                                <p>Comments/Reason</p>
                                                <textarea className={('sample_comments' in testSuiteState.errors ? ' HasError' : '')} onChange={(e) => setSampleComments(e)} name={`configuration[sample][comments]`} defaultValue={'comments' in testSuiteState.samples && testSuiteState.samples.comments ? testSuiteState.samples.comments : ''}></textarea>
                                            </div>
                                            {'sample_comments' in testSuiteState.errors && (
                                                <div className="FieldError">{testSuiteState.errors.sample_comments}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {testSuiteState.configurations.map((configuration: any, idx: any) => {
                            let status: any = configuration.status ?? 'pending',
                                recommenations: any = getRecommendations(configuration.test_id, configuration.test_configuration_id);
                            return (
                                <div className={'TestSuiteTestAssetConfiguration ' + status + ' ' + (!!configuration.open ? 'open' : '')} key={idx}>
                                    <div onClick={toggleConfiguration(idx)}>
                                        <strong>{configuration.name}</strong>
                                    </div>
                                    <span onClick={toggleConfiguration(idx)}><IconArrow width={15.91} height={24.6} /></span>
                                    <div className="content">
                                        <div className="content-inner">
                                            {['gt', 'gte', 'lt', 'lte', 'between', 'number'].indexOf(configuration.type) > -1 && (
                                                <div className={'input-unit' + ('configuration_' + configuration.id + '_value' in testSuiteState.errors ? ' HasError' : '')}>
                                                    <input type="number" step="0.01" defaultValue={'value' in configuration && configuration.value ? configuration.value : ''} onChange={(e) => checkValue(e, configuration.id)} name={'configuration[' + configuration.id + '][value]'} />
                                                    <span>{configuration.configuration.unit}</span>
                                                </div>
                                            )}
                                            {['date'].indexOf(configuration.type) > -1 && (
                                                <div className={'input-date' + ('configuration_' + configuration.id + '_value' in testSuiteState.errors ? ' HasError' : '')}>
                                                    <input type="date" defaultValue={'value' in configuration && configuration.value ? configuration.value : ''} onChange={(e) => checkValue(e, configuration.id)} name={'configuration[' + configuration.id + '][value]'} />
                                                </div>
                                            )}
                                            {['file', 'image'].indexOf(configuration.type) > -1 && (
                                                <div className={'input-file' + ('configuration_' + configuration.id + '_value' in testSuiteState.errors ? ' HasError' : '')}>
                                                    <input type="file" onChange={(e) => checkValue(e, configuration.id)} accept={(configuration.type === 'image' ? 'image/*' : configuration.configuration.allowed_extension.replace(/\*/gi, ''))} name={'configuration[' + configuration.id + '][value]'} />
                                                </div>
                                            )}
                                            {['text'].indexOf(configuration.type) > -1 && (
                                                <div className={'input-text' + ('configuration_' + configuration.id + '_value' in testSuiteState.errors ? ' HasError' : '')}>
                                                    <input type="text" defaultValue={'value' in configuration && configuration.value ? configuration.value : ''} onChange={(e) => checkValue(e, configuration.id)} name={'configuration[' + configuration.id + '][value]'} />
                                                </div>
                                            )}
                                            {['textarea'].indexOf(configuration.type) > -1 && (
                                                <div className={'input-textarea' + ('configuration_' + configuration.id + '_value' in testSuiteState.errors ? ' HasError' : '')}>
                                                    <textarea defaultValue={'value' in configuration && configuration.value ? configuration.value : ''} onChange={(e) => checkValue(e, configuration.id)} name={'configuration[' + configuration.id + '][value]'}></textarea>
                                                </div>
                                            )}
                                            {['checkbox'].indexOf(configuration.type) > -1 && (
                                                <div className={'input-checkbox' + ('configuration_' + configuration.id + '_value' in testSuiteState.errors ? ' HasError' : '')}>
                                                    <input checked={'value' in configuration && Number.parseInt(configuration.value) === 1} onChange={(e) => checkValue(e, configuration.id)} type="radio" name={'configuration[' + configuration.id + '][value]'} id={`configuration-${configuration.id}.1`} value="1" />
                                                    <label htmlFor={`configuration-${configuration.id}.1`}>{configuration.configuration.labels[1]}</label>
                                                    <input checked={'value' in configuration && Number.parseInt(configuration.value) === 0} onChange={(e) => checkValue(e, configuration.id)} type="radio" name={'configuration[' + configuration.id + '][value]'} id={`configuration-${configuration.id}.0`} value="0" />
                                                    <label htmlFor={`configuration-${configuration.id}.0`}>{configuration.configuration.labels[0]}</label>
                                                </div>
                                            )}
                                            {'configuration_' + configuration.id + '_value' in testSuiteState.errors && (
                                                <div className="FieldError">{testSuiteState.errors['configuration_' + configuration.id + '_value']}</div>
                                            )}
                                            <div className="ConfigurationActions">
                                                <div className="ButtonGroup">
                                                    {false && configuration.pass_fail === 1 && (
                                                        <>
                                                            <button type="button" className={'pass' + (configuration.status === 'pass' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'pass')}>Pass</button>
                                                            <button type="button" className={'fail' + (configuration.status === 'fail' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'fail')}>Fail</button>
                                                        </>
                                                    )}
                                                    {false && configuration.pass_fail === 0 && (
                                                        <>
                                                            <button type="button" className={'pass' + (configuration.status === 'actioned' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'actioned')}>Actioned</button>
                                                        </>
                                                    )}
                                                    <button type="button" className={'skip' + (configuration.status === 'skip' ? ' active' : '')} onClick={setConfigurationStatus(idx, 'skip')}>Skip</button>
                                                </div>
                                                {configuration.last_reading && (
                                                    <>
                                                        <button type="button" className="failed-last-reading" onClick={() => setTestSuiteState({ ...testSuiteState, failed_configuration: configuration })}>View previous failed reading</button>
                                                    </>
                                                )}
                                                <div className="ConfigurationReason">
                                                    {configuration.pass_fail === 1 && status === 'fail' && recommenations.length > 0 && (
                                                        <select defaultValue={""} onChange={setConfigurationRecommendation(idx)}>
                                                            <option value="" disabled={true}>-- Select or enter a recommendation --</option>
                                                            {recommenations.map((recommendation: any, _rindex: any) => {
                                                                return <option key={_rindex} value={recommendation.recommendation}>{recommendation.recommendation}</option>;
                                                            })}
                                                        </select>
                                                    )}
                                                    <p>Comments/Reason</p>
                                                    <textarea className={('configuration_' + configuration.id + '_comments' in testSuiteState.errors ? ' HasError' : '')} defaultValue={'comments' in configuration && configuration.comments ? configuration.comments : ''} onChange={(e) => setConfigurationComments(e, idx)} name={`configuration[${configuration.id}][comments]`}></textarea>
                                                    {'configuration_' + configuration.id + '_comments' in testSuiteState.errors && (
                                                        <div className="FieldError">{testSuiteState.errors['configuration_' + configuration.id + '_comments']}</div>
                                                    )}
                                                    {configuration.pass_fail === 1 && status === 'fail' && (
                                                        <>
                                                            <p style={{ marginTop: '0.5rem' }}>Action Priority</p>
                                                            <select defaultValue={""} onChange={setConfigurationPriority(idx)}>
                                                                <option value="none">None</option>
                                                                <option value="low">Low</option>
                                                                <option value="medium">Medium</option>
                                                                <option value="high">High</option>
                                                            </select>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {testSuiteState.status !== 'complete' && (
                            <div className="FormActions">
                                <div className="ButtonGroup">
                                    <button type="button" className="skip" onClick={skipAsset()}>Skip All</button>
                                </div>
                                {testSuiteState.skipAll && (
                                    <div className="ConfigurationReason SkipAll">
                                        <p><strong>Skip All </strong></p>
                                        <p>A comment/reason must be specified to skip one or more tasks.</p>
                                        <p>Comments/Reason</p>
                                        <textarea onChange={(e) => setSkipComments(e)}></textarea>
                                        <div className="ButtonGroup">
                                            <button type="button" className="skip" onClick={saveAsset('skip')}>Skip</button>
                                            <button type="button" onClick={(e) => closeSkip()}>Back</button>
                                        </div>
                                    </div>
                                )}
                                {Object.keys(testSuiteState.errors).length > 0 && (
                                    <p className="Error">Please check all configurations before attempting to save.</p>
                                )}
                                <div className="ButtonGroup">
                                    <button type="button" className="pass" onClick={saveAsset()}>Save</button>
                                </div>
                            </div>
                        )}
                    </>
                )}

            </form>

            {testSuiteState.failed_configuration !== false && (
                <Modal onDismiss={() => setTestSuiteState({ ...testSuiteState, failed_configuration: false })} title="Previous Failed Reading">
                    <div className="failed-last-reading-content">
                        <p><strong>Taken on:</strong><br />{moment(testSuiteState.failed_configuration.last_reading.taken_at).format('DD/MM/YYYY HH:mm')}</p>
                        <p><strong>Taken by:</strong><br />{testSuiteState.failed_configuration.last_reading.taken_by}</p>
                        <p><strong>Value:</strong><br />
                            {['gt', 'gte', 'lt', 'lte', 'between', 'number'].indexOf(testSuiteState.failed_configuration.type) > -1 && (
                                <>{testSuiteState.failed_configuration.last_reading.value} <small>{testSuiteState.failed_configuration.configuration.unit}</small></>
                            )}
                            {['checkbox'].indexOf(testSuiteState.failed_configuration.type) > -1 && (
                                <>{testSuiteState.failed_configuration.last_reading.value ? testSuiteState.failed_configuration.configuration.labels[0] : testSuiteState.failed_configuration.configuration.labels[1]}</>
                            )}
                            {['date'].indexOf(testSuiteState.failed_configuration.type) > -1 && (
                                <>{moment(testSuiteState.failed_configuration.last_reading.value, 'YYYY-MM-DD' + (testSuiteState.failed_configuration.confguration.include_time ? 'HH:mm' : '')).format('DD/MM/YYYY' + (testSuiteState.failed_configuration.configuration.include_time ? ' HH:mm' : ''))}</>
                            )}
                            {['text', 'textarea'].indexOf(testSuiteState.failed_configuration.type) > -1 && (
                                <>{testSuiteState.failed_configuration.last_reading.value}</>
                            )}
                        </p>
                        <p><strong>Priority:</strong><br />{testSuiteState.failed_configuration.last_reading.priority.substring(0, 1).toUpperCase() + testSuiteState.failed_configuration.last_reading.priority.substring(1)}</p>
                        <p><strong>Comments:</strong><br />{testSuiteState.failed_configuration.comments ?? 'N/A'}</p>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default TestSuiteTestView;