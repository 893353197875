import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import './index.scss';

function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="NotFound">
            <img src={Logo} className="LoginLogo" alt="Clearflow" width="150" height="150" />
            <p>Page not found</p>
            <button onClick={() => navigate('/')}>Back</button>
        </div>
    );
}

export default NotFound;
